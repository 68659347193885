@import url("https://fonts.googleapis.com/css?family=Sarabun:400,800");

html,
body {
  height: 100%;
  margin: 0 auto;
}

canvas:focus {
  outline: none;
}

#container {
  height: 100%;
  left: 0px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0px;
  width: 100%;
}
